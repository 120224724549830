
import {Organization} from "@/models/organizations/Organization";
import {OrganizationService} from "@/services/OrganizationService";
import {defineComponent} from "vue";
import {WebAnalytics} from "@/modules/webAnalytics";
import {getOrganizationIndustry} from "@/models/enums/OrganizationIndustry";
import {WidgetViewModel} from "@/models/WidgetViewModel";
import {PeopleService} from "@/services/PeopleService";
import {Person} from "@/models/people/Person";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {useApplication} from "@/store/pinia/applicationStore";
import {ProblemDetails} from "@/models/ProblemDetails";
import {useToast} from "vue-toastification";

export default defineComponent({
  setup() {
    const applicationStore = useApplication()
    const toast = useToast()
    return {applicationStore, toast}
  },
  data() {
    return {
      organization: {} as Organization,
      people: [] as Array<Person>,
      isFetching: false,
      organizationTypeOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Professional Theatre',
          id: 1
        }, {
          text: 'Community Theatre',
          id: 2
        }, {
          text: 'Education',
          id: 3
        }, {
          text: 'Dance',
          id: 4
        }, {
          text: 'Choir',
          id: 5
        }, {
          text: 'Tour',
          id: 6
        }, {
          text: 'Individual',
          id: 7
        },
      ]
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      if(typeof this.organizationId !== 'undefined'){
        this.isFetching = true
        OrganizationService.fetch(this.organizationId).then(response => {
          this.organization = response.data
          this.identifyOrganization()
          this.fetchPeople()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.isFetching = false
        })
      }
    },
    fetchPeople() {
      if(typeof this.organizationId !== 'undefined'){
        PeopleService.fetchOrganization(this.organizationId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        })
      }
    },
    identifyOrganization() {
      if(typeof this.organizationId !== 'undefined'){
        WebAnalytics.identifyOrganization({
          organizationId: this.organization.organizationId,
          $avatar: this.organization.photo,
          name: this.organization.name,
          $name: this.organization.name,
          StageCode: this.organization.stageCode,
          type: getOrganizationIndustry(this.organization.type).name,
          website: this.organization.website,
          email: this.organization.email,
          phone: this.organization.phone
        })
      }
    }
  },
  computed: {
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    formattedPhone(): string {
      if (this.organization.phone) {
        return this.organization.phone.replace(/[^0-9]/g, '')
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
      }
      return '';
    },
    formattedWebsite(): string {
      if (this.organization.website) {
        return this.organization.website.replace(/http?s:\/\/(www\.)?/g, '');
      }
      return '';
    },
    widgets(): Array<WidgetViewModel> {
      let widgets = [] as Array<WidgetViewModel>;
      if (this.organization.metadata) {
        const widgetMetadataRaw = this.organization.metadata.filter(x => x.type === 1);
        if (widgetMetadataRaw.length > 0) {
          widgets = JSON.parse(widgetMetadataRaw[0].value);
        }
      }
      return widgets;
    },
    activePeople(): Array<Person> {
      return this.people.filter(p => p.status === MembershipStatus.Active).slice(0, 10)
    }
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
            this.fetchPeople()
          }
        }
    )
  },
})
