import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganizationCard = _resolveComponent("OrganizationCard")!
  const _component_ProductionsTab = _resolveComponent("ProductionsTab")!
  const _component_PeopleGrid = _resolveComponent("PeopleGrid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_OrganizationCard),
    (_ctx.canManage)
      ? (_openBlock(), _createBlock(_component_ProductionsTab, { key: 0 }))
      : (_openBlock(), _createBlock(_component_PeopleGrid, { key: 1 }))
  ]))
}