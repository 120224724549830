export enum OrganizationIndustry {
    Other = 0,
    Professional = 1,
    Community = 2,
    Education = 3,
    Dance = 4,
    Choral = 5,
    Tour = 6,
    Individual = 7
}

export const getOrganizationIndustry = (status: OrganizationIndustry) => {
    switch (status) {
        case OrganizationIndustry.Other:
            return { name: 'Other', class: 'warning' }
        case OrganizationIndustry.Professional:
            return { name: 'Professional', class: 'warning' }
        case OrganizationIndustry.Community:
            return { name: 'Community', class: 'warning' }
        case OrganizationIndustry.Education:
            return { name: 'Education', class: 'warning' }
        case OrganizationIndustry.Dance:
            return { name: 'Dance', class: 'warning' }
        case OrganizationIndustry.Choral:
            return { name: 'Choral', class: 'warning' }
        case OrganizationIndustry.Tour:
            return { name: 'Tour', class: 'warning' }
        case OrganizationIndustry.Individual:
            return { name: 'Individual', class: 'warning' }
        default:
            return { name: 'Unknown', class: 'warning' }
    }
}
