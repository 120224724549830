
import {defineComponent} from "vue";
import {Person} from "@/models/people/Person";
import {PeopleService} from "@/services/PeopleService";
import {ProblemDetails} from "@/models/ProblemDetails";
import {useToast} from "vue-toastification";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {getMembershipRole, MembershipRole} from "@/models/enums/Role";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  setup() {
    const toast = useToast()
    const applicationStore = useApplication()
    return {toast, applicationStore}
  },
  data() {
    return {
      people: [] as Array<Person>,
      loading: true
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      if (this.isOrganization) {
        PeopleService.fetchOrganization(this.organizationId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.loading = false
        })
      } else {
        PeopleService.fetchProduction(this.productionId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.loading = false
        })
      }
    },
    getRole(role: MembershipRole) {
      return getMembershipRole(role);
    },
  },
  computed: {
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      if (this.isOrganization) {
        return '00000000-0000-0000-0000-000000000000'
      }
      return this.$route.params['productionId'] as string
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    activePeople(): Array<Person> {
      return this.people.filter(person => person.status === MembershipStatus.Active)
    },
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
})
