
import ModalBase from "@/components/ModalBase.vue";
import {defineComponent, ref} from "vue";
import {Production} from "@/models/productions/Production";
import {CreateProductionCommand} from "@/models/productions/CreateProductionCommand";
import {ProductionService} from "@/services/ProductionService";
import Selector2 from "@/components/inputs/SSelect2.vue";
import Button from "@/components/UI/Button.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {useUser} from "@/store/pinia/userStore";
import {bus} from "@/modules/eventBus";
import { WebAnalytics } from "@/modules/webAnalytics";

export default defineComponent({
  components: {Button, Selector2, ModalBase},
  setup(){
    const modal = ref<InstanceType<typeof ModalBase>>();
    let organizationId = ref<string>('');
    const invoke = (_organizationId) => {
      organizationId.value = _organizationId
      modal.value?.toggleOpen()
    }
    const closeModal = () => {
      modal.value?.toggleClosed()
    }
    const userStore = useUser()
    return {modal, invoke, closeModal, organizationId, v$: useVuelidate(), userStore}
  },
  data(){
    return {
      productionCommand: {name: ''} as CreateProductionCommand,
      production: {} as Production,
      isLoading: false,
      productionTypeOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Musical',
          id: 1
        },
        {
          text: 'Play',
          id: 2
        },
        {
          text: 'Opera',
          id: 3
        },
        {
          text: 'Dance',
          id: 4
        },
        {
          text: 'Concert',
          id: 5
        }
      ],
    }
  },
  validations(){
    return {
      productionCommand: {
        name: {required}
      }
    }
  },
  mounted(){
    bus.on('modals:production:invoke', (organizationId) => {
      this.invoke(organizationId as string)
    })
  },
  methods: {
    add() {
      this.v$.productionCommand.$touch()
      if (!this.v$.productionCommand.$invalid) {
        this.isLoading = true
        this.productionCommand.organizationId = this.organizationId
        ProductionService.create(this.productionCommand, this.organizationId).then(response => {
          this.userStore.fetchUser()
          this.production = response.data
          this.isLoading = false
          this.closeModal()
          this.$router.push({name: 'production-onboarding', params: {productionId: response.data.productionId}})
        }).finally(() => {
          this.isLoading = false
          WebAnalytics.trackFlexible('Created Production', {
            withSignup: false
          })
        })
      }
    }
  }
})
