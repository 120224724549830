
import {defineComponent, ref} from "vue";
import {Production} from "@/models/productions/Production";
import {OrganizationService} from "@/services/OrganizationService";
import EntityCard from "@/components/EntityCard.vue";
import CreateProductionModal from "@/components/modals/CreateProductionModal.vue";
import {ProblemDetails} from "@/models/ProblemDetails";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: "ProductionsTab",
  components: {CreateProductionModal, EntityCard},
  setup() {
    const createProductionModal = ref<InstanceType<typeof CreateProductionModal>>();
    const invokeCreateProduction = (organizationId: string) => {
      createProductionModal.value?.invoke(organizationId)
    }
    const toast = useToast();
    return {createProductionModal, invokeCreateProduction, toast}
  },
  data() {
    return {
      productions: [] as Array<Production>,
      isLoading: true
    }
  },
  mounted() {
    OrganizationService.listProductions(this.organizationId).then(response => {
      this.productions = response.data
    }).catch(err => {
      let errorDetails = err.response.data as ProblemDetails
      this.toast.error(errorDetails.detail)
    }).finally(() => {
      this.isLoading = false
    })
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    }
  }
})
