
import {defineComponent} from "vue";
import OrganizationCard from "@/components/Entity/OrganizationCard.vue";
import {Organization} from "@/models/organizations/Organization";
import ProductionsTab from "@/components/Entity/ProductionsTab.vue";
import {useUser} from "@/store/pinia/userStore";
import PeopleGrid from "@/components/Entity/PeopleGrid.vue";

export default defineComponent({
  components: {PeopleGrid, ProductionsTab, OrganizationCard},
  setup() {
    const userStore = useUser()
    return {userStore}
  },
  data() {
    return {
      organization: {} as Organization,
      isFetching: true
    }
  },
  computed: {
    canManage(): boolean {
      return this.userStore.canManage(this.organizationId)
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
  }
})
